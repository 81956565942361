import React from 'react';
import { Helmet } from 'react-helmet';

export default () => (
	<>
		<Helmet>
			<title>Coinage | Page not found</title>
		</Helmet>

		<div>
			<h1>NOT FOUND</h1>
			<p>
				Sorry, but this page doesn't exist. Please try finding what you're looking for from{' '}
				<a href="/">our home page</a>.
			</p>
		</div>
	</>
);
